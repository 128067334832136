import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
  matchPath,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
import {
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
  createTheme,
} from "@material-ui/core/styles";
import { CallState, RewardsAccount, SessionInfo } from "redmond";
import { UserContext } from "@capone/common";

import Body from "../components/Body";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { HomepageTakeover } from "../components/HomepageTakeover";
import { BasicAuth, ProtectedRoute, JWTAuth } from "../components/Auth";
import { PRIMARY_TEXT_GREY } from "../styleConstants";
import {
  PATH_HOME,
  HIDDEN_FOOTER_PATHS,
  HIDDEN_FOOTER_PATHS_MOBILE,
  HIDDEN_FOOTER_UNPROTECTED_PATHS,
  NO_FOOTER_PADDING_UNPROTECTED_PATHS,
  HIDDEN_HEADER_PATHS_DESKTOP,
  HIDDEN_HEADER_PATHS_MOBILE,
  HIDDEN_HEADER_UNPROTECTED_PATHS,
  UNPROTECTED_PATHS,
  DISPLAY_HOMEPAGE_TAKEOVER_PATHS,
  PATH_AUTH,
  NO_FOOTER_PADDING_PATHS,
  HIDDEN_BANNER_UNPROTECTED_PATHS,
  CAPONE_HEADER_UPDATED_STYLING_PATHS,
  HIDDEN_HEADER_PATHS_MOBILE_GLOBAL_NAV,
  PATH_HOTELS_AVAILABILITY,
  PATH_HOTELS_SHOP,
  PATH_STAYS_AVAILABILITY,
  PATH_CARS_AVAILABILITY,
  PATH_CARS_SHOP,
  PATH_FLIGHTS_SHOP,
  PATH_HOTELS_ANCILLARY,
  PATH_HOTELS_ANCILLARY_CUSTOMIZE,
  PATH_PACKAGES_HOTEL_AVAILABILITY,
  PATH_PACKAGE_HOTEL_SHOP,
  PATH_PACKAGE_FLIGHT_SHOP,
  PATH_PACKAGE_REVIEW,
  PATH_EXPERIENCES_AVAILABILITY,
} from "../utils/urlPaths";
import "./App.css";
import "../fonts.scss";
import { colors } from "../utils/capone/colors";
import { useWindowSize } from "../hooks/useWindowSize";
import { LoadScript } from "@react-google-maps/api";
import config from "../utils/config";
import clsx from "clsx";
import AxiosInterceptors from "../components/AxiosInterceptors";
import { useDeviceTypes } from "halifax";
import ExperimentsProvider, {
  AVAILABLE,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  getExperimentVariant,
} from "../context/experiments";
import UserSourceProvider from "../context/userSource";
import AuthModule from "../components/AuthModule";
import Maintenance from "../components/Maintenance/maintenance";
import { RootBanner } from "../components/RootBanner/component";
import { fetchRewardsAccounts } from "../api/v1/rewards/fetchRewardsAccounts";
import { FunnelEntryTabs } from "../components/Header/components/FunnelEntryTabs";
import CapOneLogo from "../assets/b2b/capone-logo.svg";
import { CAP_ONE_LOGO_ALT } from "../utils/constants";
import { fetchActiveExperiments } from "../api/v1/experiments/fetchExperiments";
import {
  setRewardsAccountsInStore,
  setSelectedAccountReferenceIdInStore,
} from "../app/actions";
import { CompatRouter } from "react-router-dom-v5-compat";

const generateClassName = createGenerateClassName({
  productionPrefix: "ptBaseModule",
  seed: "ptBaseModule",
});

const capOneTheme = {
  name: "cap-one",
  theme: createTheme({
    palette: {
      type: "light",
      primary: {
        light: "#246182",
        main: "#246182",
        contrastText: "#246182",
      },
      secondary: {
        light: "#246182",
        main: "#246182",
        contrastText: "#246182",
      },
      text: {
        primary: "rgba(80, 80, 80, 1.0)",
        secondary: "rgba(206, 207, 205, 1)",
        disabled: "#FFF",
        hint: "#FFF",
      },
    },
    typography: {
      fontFamily: "Optimist",
      htmlFontSize: 14,
      fontSize: 14,
      h1: {
        fontSize: 36,
        fontFamily: "Optimist",
        letterSpacing: 0,
      },
      h2: {
        fontSize: 24,
        fontFamily: "Optimist",
        letterSpacing: 0,
        fontWeight: 400,
      },
      h3: {
        fontFamily: "Optimist",
        fontSize: 22,
        letterSpacing: 0,
      },
      h4: {
        fontFamily: "Optimist",
        fontSize: 18,
        color: colors["primary-text-grey"],
        letterSpacing: 0,
      },
      h5: {
        fontFamily: "Optimist",
        fontSize: 18,
        color: colors["secondary-text-grey"],
        letterSpacing: 0,
      },
      h6: {
        fontFamily: "Optimist",
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
      },
      body1: {
        fontFamily: "Optimist",
        fontSize: 14,
        color: colors["primary-text-grey"],
        letterSpacing: 0,
      },
      body2: {
        fontFamily: "Optimist",
        fontSize: 14,
        color: colors["secondary-text-grey"],
        letterSpacing: 0,
      },
      caption: {
        fontFamily: "Optimist",
        fontSize: 12,
        color: colors["secondary-text-grey"],
        letterSpacing: 0,
      },
      overline: {
        fontFamily: "Optimist",
        fontSize: 14,
        letterSpacing: 0,
      },
      subtitle1: {
        fontFamily: "Optimist",
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
      },
      subtitle2: {
        fontFamily: "Optimist",
        fontSize: 12,
        letterSpacing: 0,
      },
      button: {
        fontFamily: "Optimist",
        fontSize: 14,
        textTransform: "none",
        letterSpacing: 0,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiLink: {
        root: {
          color: PRIMARY_TEXT_GREY,
        },
        underlineHover: {
          "&:hover": {
            "text-decoration": "none",
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&:focus": {
            outline: "none",
          },
          "&:focus-visible": {
            outline: "2px solid #002638",
            outlineOffset: "2px",
          },
        },
      },
      MuiButton: {
        root: {
          "&:focus-visible": {
            outline: "2px solid #002638",
            outlineOffset: "2px",
          },
        },
      },
    },
  }),
};

// TODO: add locale logic by integrating https://www.npmjs.com/package/locale-url-solver
const DEFAULT_LOCALE = "en";

export const StyleContext = createContext({});

const setTheme = () => {
  Object.keys(colors).forEach((key: string) => {
    document.body.style.setProperty(`--${key}`, (colors as any)[key]);
  });
};

const setViewWidthAndHeight = (width: string, height: string) => {
  document.body.style.setProperty(`--vw`, width);
  document.body.style.setProperty(`--vh`, height);
};

const HIDE_REWARDS_BANNER_ON_SCROLL_PATHS = [
  PATH_HOTELS_AVAILABILITY,
  PATH_STAYS_AVAILABILITY,
  PATH_HOTELS_SHOP,
  PATH_HOTELS_ANCILLARY,
  PATH_HOTELS_ANCILLARY_CUSTOMIZE,
  PATH_CARS_AVAILABILITY,
  PATH_CARS_SHOP,
  PATH_FLIGHTS_SHOP,
  PATH_PACKAGES_HOTEL_AVAILABILITY,
  PATH_PACKAGE_HOTEL_SHOP,
  PATH_PACKAGE_FLIGHT_SHOP,
  PATH_PACKAGE_REVIEW,
  PATH_EXPERIENCES_AVAILABILITY,
];

const App = () => {
  setTheme();

  const [sessionInfo, setSessionInfo] = useState({
    userInfo: { firstName: "", lastName: "", email: "" },
    isFirstSession: false,
    csrfToken: "",
    sessionExpiration: "",
    isDelegatedSession: "",
    userId: "",
  } as SessionInfo);
  const [rewardAccounts, setRewardAccounts] = useState<RewardsAccount[]>([]);
  const [selectedRewardsAccountId, setSelectedRewardsAccountId] = useState<
    string | null
  >(null);
  const [isBusinessEligible, setIsBusinessEligible] = useState(false);
  const [eligibleBusinessAccounts, setEligibleBusinessAccounts] = useState<
    RewardsAccount[]
  >([]);

  const updateSessionInfo = useCallback((sessionInfo: SessionInfo) => {
    setSessionInfo(sessionInfo);
    datadogRum.setUser({ id: sessionInfo.userId });
  }, []);

  const updateBusinessEligibility = (eligible: boolean) =>
    setIsBusinessEligible(eligible);

  const windowSize = useWindowSize();
  // Add a variable for vh to use for specifying full-screen height
  // 100vh does not work properly on iOS. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  setViewWidthAndHeight(
    `${windowSize.width * 0.01}px`,
    `${windowSize.height * 0.01}px`
  );

  const { matchesMobile } = useDeviceTypes();
  const dispatch = useDispatch();
  const [launchEventSent, setLaunchEventSent] = useState(false);
  const [fetchRewardsAccountsCallState, setFetchRewardsAccountsCallState] =
    useState<CallState>(CallState.NotCalled);
  const [isGlobalMobileNavExperiment, setIsGlobalMobileNavExperiment] =
    useState<boolean>(false);
  const [fetchGlobalMobileNavCallState, setGlobalMobileNavCallState] =
    useState<CallState>(CallState.NotCalled);

  const userContext = useMemo(
    () => ({
      sessionInfo,
      updateSessionInfo,
      isBusinessEligible,
      updateBusinessEligibility,
      eligibleBusinessAccounts,
      setEligibleBusinessAccounts,
      logo: {
        src: CapOneLogo,
        alt: CAP_ONE_LOGO_ALT,
      },
      isMultiAccount: false,
    }),
    [isBusinessEligible, sessionInfo, updateSessionInfo]
  );

  const globalMobileNavExperiment = async () => {
    try {
      const { experiments } = await fetchActiveExperiments();
      const isGlobalMobileNavExperiment =
        getExperimentVariant(experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
        AVAILABLE;
      setIsGlobalMobileNavExperiment(isGlobalMobileNavExperiment);
      setGlobalMobileNavCallState(CallState.Success);
    } catch {
      setIsGlobalMobileNavExperiment(false);
      setGlobalMobileNavCallState(CallState.Failed);
    }
  };

  useEffect(() => {
    if (!launchEventSent && sessionInfo?.csrfToken) {
      setFetchRewardsAccountsCallState(CallState.InProcess);
      const fetchRewards = async () => {
        try {
          const rewardsAccounts = await fetchRewardsAccounts();
          const sortedAccts = rewardsAccounts.sort((prev, current) => {
            const earnMultiplier = (account: RewardsAccount) =>
              prev.earn.flightsMultiplier && current.earn.flightsMultiplier // should only use flightsMultiplier if prev/current has it or else diff values are compared
                ? account.earn.flightsMultiplier
                : account.earn.hotelsMultiplier ?? -1;
            return earnMultiplier(current) - earnMultiplier(prev);
          });
          setRewardAccounts(sortedAccts);
          dispatch(setRewardsAccountsInStore(sortedAccts));
          setFetchRewardsAccountsCallState(CallState.Success);
        } catch (err) {
          setFetchRewardsAccountsCallState(CallState.Failed);
        }
      };
      fetchRewards();
      globalMobileNavExperiment();
    }
  }, [launchEventSent, sessionInfo]);

  useEffect(() => {
    if (rewardAccounts.length) {
      const highestEarnRewardsAccount = rewardAccounts.sort((prev, current) => {
        const earnMultiplier = (account: RewardsAccount) =>
          prev.earn.flightsMultiplier && current.earn.flightsMultiplier // should only use flightsMultiplier if prev/current has it or else diff values are compared
            ? account.earn.flightsMultiplier
            : account.earn.hotelsMultiplier ?? -1;
        return earnMultiplier(current) - earnMultiplier(prev);
      })[0];
      const id = highestEarnRewardsAccount?.accountReferenceId;
      setSelectedRewardsAccountId(id);
      dispatch(setSelectedAccountReferenceIdInStore(id));
      localStorage.setItem("referenceId", id);
    }
  }, [rewardAccounts]);

  useEffect(() => {
    if (window.location.search.includes("fromCorporate=true")) {
      localStorage.setItem("fromCorporate", "true");
    }
  }, []);

  const isInMaintenance = (config: {
    isMaintenanceModeEnabled: any;
    isTimedMaintenanceModeEnabled: any;
    MAINTENANCE_START_TIME?: number;
    MAINTENANCE_END_TIME?: number;
  }) => {
    if (
      config.isTimedMaintenanceModeEnabled === "true" &&
      config.MAINTENANCE_START_TIME &&
      config.MAINTENANCE_END_TIME
    ) {
      const currentTime = Date.now();
      return (
        currentTime <= config.MAINTENANCE_END_TIME &&
        currentTime >= config.MAINTENANCE_START_TIME
      );
    }
    return config.isMaintenanceModeEnabled === "true";
  };

  return (
    // note: can remove this fallback when we move to the public/env.js strategy
    isInMaintenance(config) ? (
      <Maintenance />
    ) : (
      <LoadScript googleMapsApiKey={config.googleMapsApiKey || ""}>
        <Router>
          <CompatRouter>
            <UserSourceProvider>
              <AxiosInterceptors />
              <StyleContext.Provider value={{ capOneTheme }}>
                <StylesProvider generateClassName={generateClassName}>
                  <ThemeProvider theme={capOneTheme.theme}>
                    <JWTAuth>
                      <UserContext.Provider value={userContext}>
                        <ExperimentsProvider
                          isLoggedIn={!!sessionInfo.csrfToken}
                        >
                          <div className={"App-container"}>
                            <BasicAuth redirectUrl={PATH_HOME} />
                            <Switch>
                              {stripPathLeaf()}
                              <Route
                                path={PATH_AUTH}
                                render={(
                                  browserRouterProps: RouteComponentProps
                                ) => (
                                  <AuthModule
                                    language={DEFAULT_LOCALE}
                                    {...browserRouterProps}
                                  />
                                )}
                              />
                              <Route path="*">
                                <ProtectedRoute
                                  protectedUrl={PATH_HOME}
                                  protectedContent={(pathname: string) => (
                                    <>
                                      <div
                                        className={clsx("App-content", {
                                          // TODO: verify on all URL paths (that do not render Footer) to see if any of
                                          // them should be included in NO_FOOTER_PADDING_PATHS
                                          "no-padding":
                                            NO_FOOTER_PADDING_PATHS.includes(
                                              pathname
                                            ),
                                        })}
                                      >
                                        <Route
                                          render={(props) => {
                                            if (
                                              matchesMobile
                                                ? fetchGlobalMobileNavCallState ===
                                                    CallState.Success &&
                                                  isGlobalMobileNavExperiment
                                                  ? HIDDEN_HEADER_PATHS_MOBILE_GLOBAL_NAV.includes(
                                                      props.location.pathname
                                                    )
                                                  : HIDDEN_HEADER_PATHS_MOBILE.includes(
                                                      props.location.pathname
                                                    )
                                                : HIDDEN_HEADER_PATHS_DESKTOP.includes(
                                                    props.location.pathname
                                                  )
                                            ) {
                                              return (
                                                <RootBanner
                                                  {...props}
                                                  hideOnScroll={
                                                    matchesMobile &&
                                                    isGlobalMobileNavExperiment &&
                                                    HIDE_REWARDS_BANNER_ON_SCROLL_PATHS.includes(
                                                      props.location.pathname
                                                    )
                                                  }
                                                  rewardsAccounts={
                                                    rewardAccounts
                                                  }
                                                />
                                              );
                                            }
                                            return (
                                              <>
                                                <RootBanner
                                                  {...props}
                                                  hideOnScroll={
                                                    matchesMobile &&
                                                    isGlobalMobileNavExperiment &&
                                                    HIDE_REWARDS_BANNER_ON_SCROLL_PATHS.includes(
                                                      props.location.pathname
                                                    )
                                                  }
                                                  rewardsAccounts={
                                                    rewardAccounts
                                                  }
                                                />
                                                <Header
                                                  className={
                                                    !CAPONE_HEADER_UPDATED_STYLING_PATHS.includes(
                                                      props.location.pathname
                                                    )
                                                      ? "homepage"
                                                      : ""
                                                  }
                                                  locationPath={pathname}
                                                  language={DEFAULT_LOCALE}
                                                  rewardsAccounts={
                                                    rewardAccounts
                                                  }
                                                  selectedRewardsAccountId={
                                                    selectedRewardsAccountId
                                                  }
                                                  setSelectedRewardsAccountId={
                                                    setSelectedRewardsAccountId
                                                  }
                                                />
                                              </>
                                            );
                                          }}
                                        />
                                        <Route
                                          render={(props) => {
                                            if (
                                              !DISPLAY_HOMEPAGE_TAKEOVER_PATHS.includes(
                                                props.location.pathname
                                              )
                                            ) {
                                              return null;
                                            }
                                            if (matchesMobile) {
                                              return (
                                                <>
                                                  <HomepageTakeover
                                                    {...props}
                                                    rewardsAccounts={
                                                      rewardAccounts
                                                    }
                                                  />
                                                  <FunnelEntryTabs
                                                    {...props}
                                                    isMobile
                                                  />
                                                </>
                                              );
                                            }
                                            return (
                                              <HomepageTakeover
                                                {...props}
                                                rewardsAccounts={rewardAccounts}
                                              />
                                            );
                                          }}
                                        />
                                        <Body
                                          language={DEFAULT_LOCALE}
                                          isFirstSession={
                                            sessionInfo.isFirstSession
                                          }
                                          launchEventSent={launchEventSent}
                                          setLaunchEventSent={
                                            setLaunchEventSent
                                          }
                                          className={clsx({
                                            //Only add top margin on paths with header
                                            "desktop-top-margin":
                                              !HIDDEN_HEADER_PATHS_DESKTOP.includes(
                                                pathname
                                              ) && !matchesMobile,
                                          })}
                                          rewardsAccounts={rewardAccounts}
                                          selectedRewardsAccountId={
                                            selectedRewardsAccountId
                                          }
                                          fetchRewardsAccountsCallState={
                                            fetchRewardsAccountsCallState
                                          }
                                          pathname={pathname}
                                        />
                                      </div>
                                      <Route
                                        render={(props) => {
                                          if (
                                            HIDDEN_FOOTER_PATHS.includes(
                                              props.location.pathname
                                            ) ||
                                            (matchesMobile &&
                                              HIDDEN_FOOTER_PATHS_MOBILE.includes(
                                                props.location.pathname
                                              ))
                                          ) {
                                            return null;
                                          }
                                          return (
                                            <Footer
                                              pathname={props.location.pathname}
                                            />
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                  unprotectedContent={(
                                    notAuthenticated: boolean
                                  ) => {
                                    return (
                                      <>
                                        <Route
                                          path={UNPROTECTED_PATHS}
                                          render={(prop) => {
                                            return (
                                              <>
                                                <div
                                                  className={clsx(
                                                    "App-content",
                                                    {
                                                      "no-padding":
                                                        NO_FOOTER_PADDING_UNPROTECTED_PATHS.includes(
                                                          prop.location.pathname
                                                        ),
                                                      mobile: matchesMobile,
                                                    }
                                                  )}
                                                >
                                                  <RootBanner
                                                    isHidden={
                                                      HIDDEN_BANNER_UNPROTECTED_PATHS.includes(
                                                        prop.location.pathname
                                                      ) || notAuthenticated
                                                    }
                                                    rewardsAccounts={
                                                      rewardAccounts
                                                    }
                                                    {...prop}
                                                  />
                                                  {!HIDDEN_HEADER_UNPROTECTED_PATHS.includes(
                                                    prop.location.pathname
                                                  ) && (
                                                    <Header
                                                      locationPath={
                                                        prop.location.pathname
                                                      }
                                                      language={DEFAULT_LOCALE}
                                                      rewardsAccounts={
                                                        rewardAccounts
                                                      }
                                                      selectedRewardsAccountId={
                                                        selectedRewardsAccountId
                                                      }
                                                      setSelectedRewardsAccountId={
                                                        setSelectedRewardsAccountId
                                                      }
                                                      notAuthenticated={
                                                        notAuthenticated
                                                      }
                                                    />
                                                  )}
                                                  <Body
                                                    language={DEFAULT_LOCALE}
                                                    isFirstSession={
                                                      sessionInfo.isFirstSession
                                                    }
                                                    launchEventSent={
                                                      launchEventSent
                                                    }
                                                    setLaunchEventSent={
                                                      setLaunchEventSent
                                                    }
                                                    className={clsx({
                                                      "desktop-top-margin":
                                                        !matchesMobile &&
                                                        !matchPath(
                                                          prop.location
                                                            .pathname,
                                                          HIDDEN_HEADER_UNPROTECTED_PATHS
                                                        ),
                                                    })}
                                                    rewardsAccounts={
                                                      rewardAccounts
                                                    }
                                                    selectedRewardsAccountId={
                                                      selectedRewardsAccountId
                                                    }
                                                    fetchRewardsAccountsCallState={
                                                      fetchRewardsAccountsCallState
                                                    }
                                                    pathname={
                                                      prop.location.pathname
                                                    }
                                                    notAuthenticated={
                                                      notAuthenticated
                                                    }
                                                  />
                                                </div>
                                                {!HIDDEN_FOOTER_UNPROTECTED_PATHS.includes(
                                                  prop.location.pathname
                                                ) && <Footer />}
                                              </>
                                            );
                                          }}
                                        ></Route>
                                      </>
                                    );
                                  }}
                                />
                              </Route>
                            </Switch>
                          </div>
                        </ExperimentsProvider>
                      </UserContext.Provider>
                    </JWTAuth>
                  </ThemeProvider>
                </StylesProvider>
              </StyleContext.Provider>
            </UserSourceProvider>
          </CompatRouter>
        </Router>
      </LoadScript>
    )
  );
};

export default App;

const stripPathLeaf = () => {
  return (
    <>
      {/* note: standardize the path to have EXACTLY ONE trailing slash WITHOUT index.html suffix */}
      <Route
        exact
        strict
        path="/:url*//*"
        render={(props: RouteComponentProps) => {
          return (
            <Redirect
              to={{
                ...props.location,
                pathname: props.location.pathname.replace(/\/+$/, "") + "/",
              }}
            />
          );
        }}
      />
      <Route
        exact
        path="/:url*/index.html/"
        render={(props: RouteComponentProps) => {
          return (
            <Redirect
              to={{
                ...props.location,
                pathname: props.location.pathname.slice(
                  0,
                  -"index.html/".length
                ),
              }}
            />
          );
        }}
      />
      <Route
        exact
        path="/:url*/index.html"
        render={(props: RouteComponentProps) => {
          return (
            <Redirect
              to={{
                ...props.location,
                pathname: props.location.pathname.slice(
                  0,
                  -"index.html".length
                ),
              }}
            />
          );
        }}
      />
      <Route
        exact
        strict
        path="/:url*"
        render={(props: RouteComponentProps) => {
          return (
            <Redirect
              to={{
                ...props.location,
                pathname: props.location.pathname + "/",
              }}
            />
          );
        }}
      />
    </>
  ).props.children;
  // a solution so that Switch respects Route's from a Fragment; see https://github.com/ReactTraining/react-router/issues/5785
};
